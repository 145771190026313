<div *ngIf="searchRequest$ | async as searchRequest">
    <app-error-details-search
        [initialSearchRequest]="searchRequest"
        (performSearch)="performSearch($event)"
    >
    </app-error-details-search>
</div>

<div *ngIf="searchResults$ | async as searchResults">
    <!--------------- Error filter and buttons, above the result table --------------->
    <div class="div-panel sticky-top">
        <div class="row">
            <div class="col-sm-12">
                <mat-chip-set>
                    <mat-chip style="margin-right: 1em">
                        <span
                            >Showing
                            <strong>{{ searchResults.rows.length }}</strong> of
                            <strong>{{
                                searchResults.count | number : "1.0"
                            }}</strong>
                            records</span
                        >
                    </mat-chip>

                    <ng-container
                        *ngIf="selectedCount$ | async as selectedCount"
                    >
                        <mat-chip style="margin-right: 1em">
                            <span>
                                <strong>{{
                                    selectedCount | number : "1.0"
                                }}</strong>
                                of
                                <strong>{{
                                    searchResults.count | number : "1.0"
                                }}</strong>
                                records selected</span
                            >
                        </mat-chip>

                        <button
                            mat-flat-button
                            style="margin-right: 1em"
                            (click)="archiveSelectedErrors()"
                        >
                            Archive Selected
                        </button>
                    </ng-container>

                    <button
                        mat-flat-button
                        style="margin-right: 1em"
                        type="button"
                        (click)="exportErrorsToExcel()"
                    >
                        Export
                    </button>

                    <button
                        mat-flat-button
                        style="margin-right: 1em"
                        (click)="batchCorrectCheckedErrors()"
                        *ngIf="
                            (canBatchCorrect$ | async) && allowedToBatchCorrect
                        "
                    >
                        Batch Correct Selected
                    </button>

                    <button
                        mat-flat-button
                        style="margin-right: 1em"
                        (click)="selectAllCheckBoxes()"
                        *ngIf="canSelectAll$ | async"
                    >
                        Select all
                        {{ searchResults.count | number : "1.0" }} records
                    </button>
                </mat-chip-set>
            </div>
        </div>
    </div>

    <!---------------------------------- Error Record Table -------------------------------------------->
    <div
        *ngIf="searchResults.rows.length > 0"
        style="overflow-y: auto; overflow-x: hidden; height: 65vh !important"
    >
        <div *ngIf="selectedRows$ | async as selectedRows">
            <table
                mat-table
                [dataSource]="searchResults.rows"
                matSort
                class="mat-elevation-z8"
            >
                <!-- Archive Error checkbox Column -->
                <ng-container matColumnDef="archiveCheckbox">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox
                            #selectAllCheck
                            (change)="toggleAllOnScreenSelected()"
                            [checked]="allOnScreenSelected$ | async"
                        >
                        </mat-checkbox>
                    </th>

                    <td
                        mat-cell
                        *matCellDef="let element; let i = index"
                        style="padding-top: 5px"
                    >
                        <mat-checkbox
                            [checked]="selectedRows[i]"
                            (change)="tableRowChecked(i)"
                        >
                        </mat-checkbox>
                    </td>
                </ng-container>

                <!-- Serial number Column -->
                <ng-container matColumnDef="serialNumberReferences">
                    <th mat-header-cell *matHeaderCellDef>Serial Numbers</th>
                    <td
                        mat-cell
                        *matCellDef="let element"
                        (click)="onErrorRowClicked(element)"
                    >
                        <a class="text-link">{{
                            element.serialNumberReferences.join(", ")
                        }}</a>
                    </td>
                </ng-container>

                <!-- Product Number -->
                <ng-container matColumnDef="productNumberReferences">
                    <th mat-header-cell *matHeaderCellDef>Product Numbers</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.productNumberReferences.join(", ") }}
                    </td>
                </ng-container>

                <!-- Sending Unit -->
                <ng-container matColumnDef="sendingUnit">
                    <th mat-header-cell *matHeaderCellDef>Sending Unit</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.sendingUnit }}
                    </td>
                </ng-container>

                <!-- Sending System -->
                <ng-container matColumnDef="sendingSystem">
                    <th mat-header-cell *matHeaderCellDef>Sending System</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.sendingSystem }}
                    </td>
                </ng-container>

                <!-- Caller Name -->
                <ng-container matColumnDef="callerName">
                    <th mat-header-cell *matHeaderCellDef>Caller Name</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.callerName }}
                    </td>
                </ng-container>

                <!-- Events -->
                <ng-container matColumnDef="events">
                    <th mat-header-cell *matHeaderCellDef>Events</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.events | eventCode }}
                    </td>
                </ng-container>

                <!-- Error categories -->
                <ng-container matColumnDef="errorCategories">
                    <th mat-header-cell *matHeaderCellDef>Category</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.errorCategories | errorCategory }}
                    </td>
                </ng-container>

                <!-- Creation Date -->
                <ng-container matColumnDef="creationDate">
                    <th mat-header-cell *matHeaderCellDef>Creation Date</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.creationDate }}
                    </td>
                </ng-container>

                <tr
                    mat-header-row
                    *matHeaderRowDef="displayedColumns; sticky: true"
                ></tr>
                <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                ></tr>
            </table>
            <mat-paginator
                [pageSizeOptions]="pageSizeOptions"
                [pageSize]="pageSize$ | async"
                [length]="searchResults.count"
                [pageIndex]="pageIndex$ | async"
                (page)="loadMoreRows($event)"
                showFirstLastButtons
            ></mat-paginator>
        </div>
    </div>

    <!----------------------------------Show error message when there are no records to display----------------->
    <div class="empty-result-message" *ngIf="searchResults.count == 0">
        <div style="margin-left: 40% !important">
            <i class="fa fa-search"></i>
            <p>There are no error records which match your search.</p>
        </div>
    </div>
</div>
